import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import SectionProductBanner from '../components/SectionProductBanner/SectionProductBanner';
import ProductIngredientsImage from '../components/ProductIngredientsImage/ProductIngredientsImage';
import ProductIngredientsInfo from '../components/ProductIngredientsInfo/ProductIngredientsInfo';
import SectionGrid from '../components/SectionGrid/SectionGrid';
import Seo from '../components/seo';
import { useUrl } from '../fragments/hooks/useUrl';
import { useLocation } from "@reach/router"
import ReviewAndRatings from '../components/ReviewAndRatings/ReviewAndRatings';
import { useLocale } from '../components/hooks/useLocale';
import SectionQA from '../components/SectionQusestionAnswer/SectionQA';
import Breadcrum from '../components/Breadcrum/Breadcrum';
const Product = ({
  pageContext,
  location,
  data: {
    contentfulPageProduct: {
      slug,
      seoTitle,
      seoDescription,
      breadcrum,
      thumbnailTitle,
      thumbnailImage,
      baseColor,
      sku,
      sections
    },
    allContentfulPageProduct:{
        nodes
    }
  }
}) => {
  const lang = useUrl()
  const locale = useLocale()
  const urlLocation = useLocation()
  const [productId, setProductId] = useState(sku)
  const updateSku = (id) =>{
    setProductId(id)
  }
  useEffect(() => {
    if (baseColor) {
      document.documentElement.style.setProperty(`--product-color`, `${baseColor}`)
      return () => {
        document.documentElement.style.removeProperty(`--product-color`)
      }
    }
  }, [])
  
  useEffect(() =>{
    setTimeout(() => {
        if (PriceSpider) {
            PriceSpider.rebind();
        }
      }, 0)
  }, [])
  
  if(!sections){
    return <div></div>
  }

  return (
    <div id="product">
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        image = {thumbnailImage}
        schema={{
          "@context": "http://schema.org/",
          "@type": "Product",
          'name': seoTitle && seoTitle,
          'description': seoDescription && seoDescription.seoDescription,
          'image': thumbnailImage.file.url,
          'brand': 'Tazo',
          'category': 'Tea',
          'gtin': sku,
          'sku': sku,
          'productID': sku,
        }}
        />
      {breadcrum && <Breadcrum data ={breadcrum} title = {thumbnailTitle}/>}
        
      {sections.map((section: any, index: number) => {
        switch (section.__typename) {
          case 'ContentfulProductHero':
            return <SectionProductBanner data={section} key={index} baseColor={baseColor} sku = {sku} updateSku = {updateSku}/>
          case 'ContentfulSectionWithTextAndImage':
            return <ProductIngredientsImage data={section} key={index} />
          case 'ContentfulSectionIngredients':
            return <ProductIngredientsInfo data={section} key={index} baseColor={baseColor} />
          case "ContentfulComponentGridSection":
            return <SectionGrid data={section} key={index} />
        }
      })}
      {<ReviewAndRatings sku = {productId} />}
      {(locale === 'en-US' || locale === 'es-US') && <SectionQA sku = {productId} />}
    </div>
  )
}

export const ProductQuery = graphql`
    query ProductQuery( $id: String!, $contentful_id: String! ){
        contentfulPageProduct(id: {eq: $id}){
            id
            node_locale
            slug
            seoTitle
            seoDescription {
              seoDescription
            }
            breadcrum {
              title
              slug
            }
            thumbnailTitle
            thumbnailImage {
              gatsbyImageData
              title
              file{
                url
              }
            }
            sku
            baseColor
            sections{
              __typename
              ...contentfulProductHero
              ...sectionWithTextImage
              ...sectionIngredients
              ...contentfulComponentGridSection
            }
        }
        allContentfulPageProduct(
            filter: {
                contentful_id: { eq: $contentful_id },
                id: { ne: $id },
                slug: { ne: null }
            }
        ) {
            nodes {
                slug
                node_locale
            }
        } 
    }
`
export default Product