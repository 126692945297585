import React, { useRef, useState, useEffect } from "react"
import * as styles from "./productHero.module.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"

import { Pagination } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import {BiMap} from "react-icons/bi"
import { RichText } from "../Shared/RichText/RichText"
import SocialMedia from '../SocialMediaIcons/SocialMedia'
import { useLocalPath } from "../hooks/useLocalPath"
import { useLocale } from "../../fragments/hooks/useLocale"
const ProductHero = ({ variant, data, setter, baseColor, currentIndex, sku, updateSku }) => {
  const { sizes} = data
  const { images, title, caffeineLevel, productDescription, ctaBuyButton, ctaFindInStore } = variant
  const swiperRef = useRef()
  const [currentImage, setCurrentImage] = useState(0)
  const [currentSku, setCurrentSku] = useState(sku)
  const locale = useLocale()
  const handler = (id, sku) =>{
    setter(id)
    setCurrentSku(sku)
    updateSku(sku)
  }
  const slideChange = () => {
    if (swiperRef?.current?.swiper?.activeIndex)
      setCurrentImage(swiperRef?.current?.swiper?.activeIndex)
  }
  const goNextThumbnail = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrevThumbnail = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }
  
  useEffect(() =>{
    let node = document.getElementById('caffiene')?.getElementsByTagName('li')
    if(node && caffeineLevel && caffeineLevel > 0){
      for(let i = 0; i < caffeineLevel; i++){
        node[i].style.background= "#000"
      }
    }
  }, [])
  useEffect(() => {
    const currentSize =  document.querySelector('#current-size')
    currentSize.children[currentIndex].setAttribute("class", "active")
   }, [currentIndex])

   useEffect(() =>{
    setTimeout(() => {
        if (PriceSpider) {
            PriceSpider.rebind();
        }
      }, 0)
  }, [])

  const ratings = () =>{
    return(
      <div className={styles.ratings}>
        <div data-bv-show="rating_summary" data-bv-product-id={currentSku}></div>
      </div>
    )}

const buyNow = (locale) =>{ 
  switch(locale){
      case 'en-CA':
          return <div><button className="button" style={{background: baseColor, border: `1px solid ${baseColor}`}}><a href= 'https://www.amazon.ca/stores/TAZO/page/6F8C3F11-A546-4887-B2F4-8305958B0DDA?ref_=ast_bln' target='_blank' rel="noopener noreferrer"
          aria-label= {`Link opens in a new window`}
          title="Link opens in a new window">Buy Now</a></button></div>
      case 'fr-CA':
          return <div><button className="button" style={{background: baseColor, border: `1px solid ${baseColor}`}}><a href= 'https://www.amazon.ca/stores/TAZO/page/6F8C3F11-A546-4887-B2F4-8305958B0DDA?ref_=ast_bln' target='_blank' rel="noopener noreferrer"
          aria-label= {`Link opens in a new window`}
          title="Link opens in a new window">ACHETER</a></button></div>
      default:
          return(
              <>
                  <div className={`ps-widget ${styles.button}`} style={{background: baseColor, border: `1px solid ${baseColor}`, minWidth: '183px', borderRadius: 0, paddingTop: '4px'}} ps-sku={currentSku}></div>
                  {/* {ctaFindInStore && <div><button className={`${styles.transparentButton} ${styles.findStore}`} > <BiMap style={{marginRight: '5px'}}/><Link to= {useLocalPath(ctaFindInStore.slug)}>{ctaFindInStore.title}</Link></button></div>} */}
              </>
          )
  }
}
  return (
    <div className={styles.wrapper}>
      <div className={styles.heroContainer}>
        <div className={styles.hero}>
          <div className={styles.heroImage}>
            <GatsbyImage
              image={images[currentImage].gatsbyImageData}
              alt={`${title || images[currentImage].title} image`}
              className={styles.heroImage}
              objectFit="contain"
              loading="eager"
            />
          </div>
          <aside className={styles.mediaThumbnail}>
            <div className={styles.arrow} onClick={goPrevThumbnail}>
              <FiChevronLeft />
            </div>
            <Swiper
              ref={swiperRef}
              spaceBetween={20}
              className={styles.swiperContainer}
              onSlideChange={slideChange}
              slidesPerView= {3}
              breakpoints={{
                320: {
                  width: 352,
                  slidesPerView: 3,
                  spaceBetween:10
                },
                767: {
                  slidesPerView: 4,
                },
              }}
              // centeredSlides={true}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index} onClick={() => setCurrentImage(index)}>
                  <GatsbyImage
                    objectFit="contain"
                    className={styles.thumbnailImage}
                    image={image.gatsbyImageData}
                    alt={`${images[currentImage].title || title } image`}
                    loading="eager"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={styles.arrow} onClick={goNextThumbnail}>
              <FiChevronRight />
            </div>
          </aside>
        </div>
        <div className={styles.sizeContainer} id="current-size">
          {sizes.map((item:any, id:number) => {
            const { title, slug, sku } = item
            return( 
                <Link to={`#${slug}`} key={id} >
                    <div className={id === currentIndex? styles.sizeOptionActive :styles.sizeOption} onClick = {(() => handler(id, sku))}>
                        {title}
                    </div>
                </Link>
            )
          })}
        </div>
      </div>
      <div className={styles.productInfo}>
        <h1 className={styles.title}>{title}</h1>
        {ratings()}
        <div className={styles.caffeineInfo}>
          <h2 className={styles.caffeineLevel}>Caffeine Level</h2>
          <ul id="caffiene">
            <li className={styles.circle}></li>
            <li className={styles.circle}></li>
            <li className={styles.circle}></li>
            <li className={styles.circle}></li>
            <li className={styles.circle}></li>
          </ul>
        </div>
        <div className={styles.productDesc}>
          <RichText content={productDescription} />
          <div className={styles.social}>
            <SocialMedia />
          </div>
        </div>
        <div className={styles.cta}>
          {buyNow(locale)}
        </div>
      </div>
    </div>
  )
}

export default ProductHero
