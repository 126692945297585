import React from 'react'
import Title from '../Shared/Title/Title'
import * as styles from './sectionQA.module.scss'
const SectionQA = ({sku}) => {
  return (
    <div className='container container-lg'>
        <div className={styles.title}>
            <Title title = 'QUESTIONS AND / ANSWERS' headingLevel='h3' />
        </div>
        <div 
            data-bv-show="questions"
            data-bv-product-id={sku}>
        </div>
    </div>
  )
}

export default SectionQA