// extracted by mini-css-extract-plugin
export var button = "productIngredientsImage-module--button--5b41f";
export var cafeFormIntro = "productIngredientsImage-module--cafe-form-intro--337e8";
export var cardContainer = "productIngredientsImage-module--card-container--18e49";
export var cardDescription = "productIngredientsImage-module--card-description--186bc";
export var cardTitle = "productIngredientsImage-module--card-title--b79bc";
export var highlight = "productIngredientsImage-module--highlight--68f9b";
export var imageContainer = "productIngredientsImage-module--image-container--ee2fa";
export var img = "productIngredientsImage-module--img--43866";
export var redButton = "productIngredientsImage-module--red-button--ab24d";
export var redTransparentButton = "productIngredientsImage-module--red-transparent-button--2fc0b";
export var swiperContainer = "productIngredientsImage-module--swiper-container--60565";
export var title = "productIngredientsImage-module--title--9c9c6";
export var transparentButton = "productIngredientsImage-module--transparent-button--f2f28";
export var wrapper = "productIngredientsImage-module--wrapper--d8d6e";