import React, {useState} from 'react'
import * as styles from './sectionProductBanner.module.scss'
import ProductHero from '../ProductHero/ProductHero';

const SectionProductBanner = ({data, baseColor, sku, updateSku}) => {
    const{variants, ...restData} = data
    const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <div className={`container container-lg ${styles.wrapper}`}>
        <ProductHero variant = {variants[currentIndex]}  data = {restData} setter = {setCurrentIndex} baseColor={baseColor} currentIndex = {currentIndex} sku = {sku} updateSku = {updateSku}/>
    </div>
  )
}

export default SectionProductBanner