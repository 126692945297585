import React, {useRef, useLayoutEffect, useState} from 'react'
import * as styles from './productIngredientsImage.module.scss'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination,Keyboard } from "swiper"

import Title from '../Shared/Title/Title'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductIngredientsImage = ({ data }) => {
    const { title, multipleImagesWithText } = data
    const swiperRef = useRef()
    const [windowSize, setWindowSize] = useState({ width: 0})

    const handleSize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };
  
    useLayoutEffect(() => {
      handleSize();
  
      window.addEventListener("resize", handleSize);
  
      return () => window.removeEventListener("resize", handleSize);
    }, []);

    return (
        <div className={`container container-md ${styles.wrapper}`}>
            <h2 className={styles.title}><Title title={title} /></h2>
            <div className={styles.cardContainer}>
            <Swiper
              ref={swiperRef}
              spaceBetween={20}
              loop={windowSize.width > 760 ? multipleImagesWithText?.length > 4 ? true : false : multipleImagesWithText?.length > 1 ? true : false}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                clickable: true,
              }}
              className={styles.swiperContainer}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                520: {
                    slidesPerView: 1,
                    centeredSlides:true
                  },
                767: {
                  slidesPerView: 4,
                },
              }}
              modules={[Keyboard,Pagination]}
              slidesPerView={1}
              // centeredSlides={true}
            >
                {multipleImagesWithText.map((card: any, id: number) => {
                    const { image, title, description } = card
                    return(
                    <SwiperSlide key={id}  >
                        <div className={styles.imageContainer}>
                            {image && <div className={styles.img}>
                                <GatsbyImage
                                    objectFit="contain"
                                    image={image.gatsbyImageData}
                                    alt={`${title} image`}
                                    loading="lazy"
                                />
                            </div>}
                            <div className={styles.cardTitle}> <strong>{title}</strong></div>
                            <div className={styles.cardDescription}>{description.description}</div>
                        </div>
                    </SwiperSlide>
                    )
                })}
            </Swiper>
            </div>
        </div>
    )
}

export default ProductIngredientsImage