import React from 'react'
import Title from '../Shared/Title/Title'
import * as styles from './reviewAndRatings.module.scss'
const ReviewAndRatings = ({sku}) => {
  return (
    <div className={`container container-lg ${styles.box}`}>
        <div className={styles.title}>
            <Title title = 'WHAT CONSUMERS / ARE SAYING' headingLevel='h3' />
        </div>
        <div  data-silktide="ignore">
            <div data-bv-show="reviews" data-bv-product-id={sku}></div>
        </div>
    </div>
  )
}

export default ReviewAndRatings