// extracted by mini-css-extract-plugin
export var amount = "productIngredientsInfo-module--amount--5af9f";
export var boxLeft = "productIngredientsInfo-module--box-left--1cb59";
export var boxRight = "productIngredientsInfo-module--box-right--e5720";
export var boxWrapper = "productIngredientsInfo-module--box-wrapper--4a5da";
export var button = "productIngredientsInfo-module--button--04ea8";
export var cafeFormIntro = "productIngredientsInfo-module--cafe-form-intro--e7dda";
export var caffeineDetail = "productIngredientsInfo-module--caffeine-detail--d7131";
export var caffeineLevel = "productIngredientsInfo-module--caffeine-level--abde7";
export var caffeineTitle = "productIngredientsInfo-module--caffeine-title--1c0de";
export var card = "productIngredientsInfo-module--card--7a6ff";
export var cardDescription = "productIngredientsInfo-module--card-description--0c481";
export var cardImage = "productIngredientsInfo-module--card-image--bb618";
export var cardInfoWrapper = "productIngredientsInfo-module--card-info-wrapper--46676";
export var decaffeineTitle = "productIngredientsInfo-module--decaffeine-title--21bd4";
export var highligh = "productIngredientsInfo-module--highligh--45488";
export var highlight = "productIngredientsInfo-module--highlight--a925b";
export var ingredients = "productIngredientsInfo-module--ingredients--72007";
export var ingredientsInfoTitle = "productIngredientsInfo-module--ingredients-info-title--d995b";
export var ingredientsText = "productIngredientsInfo-module--ingredients-text--55c6d";
export var ingredientsWrapper = "productIngredientsInfo-module--ingredients-wrapper--aea6e";
export var redButton = "productIngredientsInfo-module--red-button--40b48";
export var redTransparentButton = "productIngredientsInfo-module--red-transparent-button--3a3e7";
export var steepingInfoTitle = "productIngredientsInfo-module--steeping-info-title--a5093";
export var steps = "productIngredientsInfo-module--steps--de7d8";
export var title = "productIngredientsInfo-module--title--c4db2";
export var transparentButton = "productIngredientsInfo-module--transparent-button--16d05";