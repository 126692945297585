import React, {useEffect} from 'react'
import * as styles from './productIngredientsInfo.module.scss'
import Title from '../Shared/Title/Title'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from '../Shared/RichText/RichText'

const ProductIngredientsInfo = ({data, baseColor}) => {
    const {title, steepingInfoTitle, steepingInfoCards, ingredientsTitle, ingredientsText, caffieneGuideTitle, decaffeinatedTitle, decaffeinatedText, caffieneLevel} = data
    
    useEffect(() =>{
        let level = document.getElementById('caffiene-amont')?.getElementsByTagName('li')
        if(level && caffieneLevel && caffieneLevel > 0){
            for(let i = 1; i < level.length ; i++){
                level[caffieneLevel-1].classList.add('highlight')
            }
          }
    }, [])
  return (
    <div className='conatiner' style={{background: baseColor}}>
        <div className='container-lg'>
            <h2 className={styles.title}><Title title = {title}/></h2>
            <div className={styles.boxWrapper}>
                { steepingInfoTitle && steepingInfoCards &&
                <div className={styles.boxLeft}>
                    <div>
                        <h3 className={styles.steepingInfoTitle}>{steepingInfoTitle}</h3>
                    </div>
                    <ul className={styles.cardInfoWrapper}>
                        {steepingInfoCards.map((card:any, id:number) =>{
                            const{image, description} =  card
                            return(
                                <li className={styles.card} key = {id}>
                                    <div className={styles.steps}>{id+1}</div>
                                    <div >
                                        <GatsbyImage
                                            image = {image.gatsbyImageData}
                                            alt = {`${image.title} image`}
                                            className={styles.cardImage}
                                            objectFit= 'contain'/>
                                    </div>
                                    <div>
                                        <p className={styles.cardDescription}>{description.description}</p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>}
                { ingredientsTitle && ingredientsText && caffieneGuideTitle && 
                <div className={styles.boxRight}>
                    <div className={styles.ingredientsWrapper}>
                        <div className={styles.ingredients}>
                            <h3 className={styles.ingredientsInfoTitle}>{ingredientsTitle}</h3>
                            <p className={styles.ingredientsText}><RichText content= {ingredientsText} /></p>
                        </div>
                        <div>
                            <p className={styles.caffeineTitle}>{caffieneGuideTitle}</p>
                            <ul className={`${styles.caffeineDetail}`} id = 'caffiene-amont'>
                                <li className={styles.caffeineLevel}>
                                    <span className={styles.amount}>1 - 15 mg</span>
                                </li>
                                <li className={styles.caffeineLevel}>
                                    <span className={styles.amount}>16 - 30 mg</span>
                                </li>
                                <li className={styles.caffeineLevel}>
                                    <span className={styles.amount}>31 - 45 mg</span>
                                </li>
                                <li className={styles.caffeineLevel}>
                                    <span className={styles.amount}>46 - 60 mg</span>
                                </li>
                                <li className={styles.caffeineLevel}>
                                    <span className={styles.amount}>61+ mg</span>
                                </li>
                            </ul>
                            <p className={styles.decaffeineTitle}>{decaffeinatedTitle}</p>
                            <p className={styles.ingredientsText}>{decaffeinatedText.decaffeinatedText}</p>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default ProductIngredientsInfo